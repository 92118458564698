import "./App.css";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import Main from "./pages/Main";

function App() {
  return (
    <div className="gdc-app">
      <NavigationBar></NavigationBar>
      <Main></Main>
    </div>
  );
}

export default App;
