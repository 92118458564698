import { Alignment, Button, Navbar } from "@blueprintjs/core";
import React from "react";

function NavigationBar() {
  return (
    <div className="gdc-navigation-bar">
      <Navbar fixedToTop={true}>
        <Navbar.Group align={Alignment.LEFT}>
          <Button className="" icon="home" text="application-name"></Button>
          <Navbar.Divider></Navbar.Divider>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <Button icon="form" text="Adatfelviteli oldal"></Button>
        </Navbar.Group>
      </Navbar>
    </div>
  );
}

export default NavigationBar;
