import { Card, Elevation } from "@blueprintjs/core";
import React from "react";
import "./Center.css";

function Center() {
  return (
    <div className="gdc-center">
      <Card elevation={Elevation.TWO} className="gdc-center-card"></Card>
    </div>
  );
}

export default Center;
