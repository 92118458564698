import React from "react";
import Center from "../../components/Center/Center";

function FormPage() {
  return (
    <div className="gdc-form-page">
      <Center></Center>
    </div>
  );
}

export default FormPage;
