import React from "react";
import FormPage from "./FormPage.js/FormPage";

function Main() {
  return (
    <div className="gdc-main">
      <FormPage></FormPage>
    </div>
  );
}

export default Main;
